import {
  json,
  redirect,
  type LinksFunction,
  type LoaderFunctionArgs,
  type MetaFunction,
} from '@remix-run/node'
import {
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  ShouldRevalidateFunction,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useRouteError,
} from '@remix-run/react'
import {
  captureRemixErrorBoundaryError,
  setUser as setSentryUser,
  withSentry,
} from '@sentry/remix'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/remix'
import { Provider, useSetAtom } from 'jotai'
import { useHydrateAtoms } from 'jotai/utils'
import posthog from 'posthog-js'
import { useEffect } from 'react'
import {
  aCookieContact,
  aOrganization,
  aOriginalLoginUser,
  aUser,
} from '~/atoms'
import { Toaster } from '~/components/ui'
import type { CookieContact, ModelUser, Organization, User } from '~/models'
import { findOrganization, findUser } from '~/mongo.server'
import { createIdbPersister } from '~/utils'
import {
  contactCookie,
  deleteCookieTokenHeader,
  env,
  getCookieToken,
  getOrganizationSlug,
  hasHeaderToken,
  parseTokenUser,
} from '~/utils.server'
import { useIsEmbed } from './hooks'
import mapboxStyles from './styles/mapbox-gl.css?url'
import styles from './tailwind.css?url'

declare global {
  var __data: {} | undefined
}

export const shouldRevalidate: ShouldRevalidateFunction = ({ currentUrl }) => {
  return currentUrl.pathname === '/login' || currentUrl.pathname === '/redirect'
}

// const linkArray = [
//   {
//     rel: 'stylesheet',
//     href:
//       process.env.NODE_ENV === 'development'
//         ? require('~/tailwind.css?url')
//         : require('~/tailwind.css'),
//   },
//   {
//     rel: 'stylesheet',
//     href:
//       process.env.NODE_ENV === 'development'
//         ? require('~/styles/mapbox-gl.css?url')
//         : require('~/styles/mapbox-gl.css'),
//   },
//   { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
//   { rel: 'preconnect', href: 'https://fonts.gstatic.com' }, // crossorigin?
//   {
//     rel: 'stylesheet',
//     href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap',
//   },
// ]

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: styles },
  { rel: 'stylesheet', href: mapboxStyles },
  { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
  { rel: 'preconnect', href: 'https://fonts.gstatic.com' }, // crossorigin?
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Source+Sans+3:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap',
  },
  {
    rel: 'icon',
    href: '/favicon.ico',
    sizes: 'any',
  },
  {
    rel: 'apple-touch-icon',
    href: '/apple-touch-icon.png',
  },
]

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: 'Associate' },
    {
      property: 'og:image',
      content: data?.organization?.meta.share
        ? data.organization.meta.share.url
        : '/open-graph.png',
    },
  ]
}

type LoaderData = {
  organization: Organization
  user?: User
  originalLoginUser?: ModelUser
  cookieContact?: CookieContact
  ENV: {
    VITE_FILE_UPLOAD_URL: string
    VITE_REPORT_MAP_URL: string
  }
}

export const loader = async ({ request }: LoaderFunctionArgs) => {
  let slug = getOrganizationSlug(request)

  if (env.NODE_ENV === 'development' && global.__data) {
    // return json(global.__data)
  }

  const organization = await findOrganization({ slug })

  if (!organization) {
    throw new Response('Not Found', { status: 404 })
  }

  if (request.url.includes('/login') || request.url.includes('/redirect')) {
    return {
      organization,
      ENV: {
        VITE_FILE_UPLOAD_URL: env.FILE_UPLOAD_URL,
        VITE_REPORT_MAP_URL: env.REPORT_MAP_URL,
        NODE_ENV: env.NODE_ENV,
      },
    }
  }

  const token = await getCookieToken(request)
  if (
    request.url.includes('/admin') &&
    (!hasHeaderToken(request) || token === null)
  ) {
    return redirect('/login')
  }

  let user = null
  let originalLoginUser = null
  if (token !== null) {
    const parsedUser = parseTokenUser(token)
    if (parsedUser !== null) {
      user = await findUser({
        _id: parsedUser._id,
        'organization._id': organization._id,
      })
      originalLoginUser = parsedUser.originalLoginUser
    } else {
      return redirect('/login', {
        headers: await deleteCookieTokenHeader(),
      })
    }
  }

  // Members Only Organization
  if (organization.options.membersOnly && !user) {
    return redirect('/login')
  }

  const cookieContact = await contactCookie.parse(
    request.headers.get('Cookie') || ''
  )

  const data = {
    organization,
    user,
    originalLoginUser,
    cookieContact,
    ENV: {
      VITE_FILE_UPLOAD_URL: env.FILE_UPLOAD_URL,
      VITE_REPORT_MAP_URL: env.REPORT_MAP_URL,
      NODE_ENV: env.NODE_ENV,
    },
  }

  if (env.NODE_ENV === 'development') {
    global.__data = data
  }

  return json(data)
}

function DebugScreenSizes() {
  if (process.env.NODE_ENV !== 'development') {
    return null
  }
  return (
    <div className="fixed left-0 top-0 z-[999] bg-red-500 px-2 py-1 text-white print:hidden">
      <span className="sm:hidden">xs</span>
      <span className="hidden sm:inline-block md:hidden">sm</span>
      <span className="hidden md:inline-block lg:hidden">md</span>
      <span className="hidden lg:inline-block xl:hidden">lg</span>
      <span className="hidden xl:inline-block 2xl:hidden">xl</span>
      <span className="hidden 2xl:inline-block">2xl</span>
    </div>
  )
}

function HydrationContainer({
  organization,
  user,
  originalLoginUser,
  cookieContact,
}: {
  organization: Organization
  user?: User
  originalLoginUser?: ModelUser
  cookieContact?: CookieContact
}) {
  useHydrateAtoms([[aOrganization, organization]])
  useHydrateAtoms([[aUser, user ?? null]])
  useHydrateAtoms([[aOriginalLoginUser, originalLoginUser ?? null]])
  useHydrateAtoms([[aCookieContact, cookieContact ?? null]])

  const setUser = useSetAtom(aUser)
  const setOriginalLoginUser = useSetAtom(aOriginalLoginUser)

  useEffect(() => {
    setUser(user ?? null)
    setOriginalLoginUser(originalLoginUser ?? null)
  }, [user])

  return <Outlet />
}

function Document({
  ENV,
  organization,
  user,
  children,
}: {
  ENV?: any
  organization?: Organization
  user?: User
  children: React.ReactNode
}) {
  return (
    <html lang="en" className="scroll-pt-40 overflow-x-hidden">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        {ENV && (
          <script
            dangerouslySetInnerHTML={{
              __html: `window.ENV = ${JSON.stringify(ENV)}`,
            }}
          />
        )}
      </head>
      <body
        className="!opacity-100 transition-opacity duration-300"
        style={{ opacity: 0 }}>
        {children}
        <ScrollRestoration />
        <script
          async
          src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAv5MD0Ed191OORtaC3YkigbkXkWq3JnOY&libraries=places&callback=Function.prototype"
        />
        <Scripts />

        {ENV?.NODE_ENV === 'production' && (
          <>
            <Analytics />
            <SpeedInsights />
          </>
        )}

        {user && (
          <>
            <script
              dangerouslySetInnerHTML={{
                __html: `window.intercomSettings = {
              api_base: "https://api-iam.intercom.io",
              app_id: "a3v8n6l5",
              name: "${
                user ? `${user.firstName} ${user.lastName}` : undefined
              }",
              user_id: "${user?._id || undefined}",
              email: "${user?.email || undefined}",
              created_at: ${undefined} // Signup date as a Unix timestamp
            };`,
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `
              // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/a3v8n6l5'
              (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/a3v8n6l5';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`,
              }}
            />
          </>
        )}
      </body>
    </html>
  )
}

const queryClient = new QueryClient()

function App() {
  const { organization, user, originalLoginUser, cookieContact, ENV } =
    useLoaderData<LoaderData>()
  const location = useLocation()
  const isEmbed = useIsEmbed()

  useEffect(() => {
    posthog.capture('$pageview')
  }, [location.pathname])

  useEffect(() => {
    if (user) {
      // Test original logged in user
      posthog.identify(user.tid, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (user) {
      setSentryUser({ email: user.email })
    } else {
      setSentryUser(null)
    }
  }, [user])

  useEffect(() => {
    const outer = document.createElement('div')
    outer.style.visibility = 'hidden'
    outer.style.width = '100px'
    document.body.appendChild(outer)

    const widthNoScroll = outer.offsetWidth
    outer.style.overflow = 'scroll'

    const inner = document.createElement('div')
    inner.style.width = '100%'
    outer.appendChild(inner)

    const widthWithScroll = inner.offsetWidth
    outer.parentNode?.removeChild(outer)

    if (widthNoScroll - widthWithScroll > 0) {
      document.documentElement.classList.add('scrollbar')
    }
  }, [])

  return (
    <Document ENV={ENV} organization={organization} user={user}>
      <DebugScreenSizes />
      <Provider>
        {isEmbed ? (
          <QueryClientProvider client={queryClient}>
            <HydrationContainer
              organization={organization}
              user={user}
              originalLoginUser={originalLoginUser}
              cookieContact={cookieContact}
            />
          </QueryClientProvider>
        ) : (
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister: createIdbPersister(),
              maxAge: 1000 * 60 * 60 * 24 * 5,
            }}>
            <HydrationContainer
              organization={organization}
              user={user}
              originalLoginUser={originalLoginUser}
              cookieContact={cookieContact}
            />
          </PersistQueryClientProvider>
        )}
      </Provider>
      <Toaster />
    </Document>
  )
}

export default withSentry(App)

export function ErrorBoundary() {
  const error = useRouteError()
  const renderError = () => {
    if (isRouteErrorResponse(error)) {
      return (
        <div className="mx-auto mt-10 flex flex-col justify-around px-4 py-8 text-center ">
          <h1 className="my-4 text-2xl">
            {error.status} {error.statusText}
          </h1>

          <p className="py-4">
            The page you requested does not exist. Click{' '}
            <Link to="/admin" className="underline">
              here
            </Link>{' '}
            to return to the dashboard.
          </p>
        </div>
      )
    } else if (error instanceof Error) {
      return (
        <div>
          <h1>Error</h1>
          <p>{error.message}</p>
          <p>The stack trace is:</p>
          <pre>{error.stack}</pre>
        </div>
      )
    } else {
      return <h1>Unknown Error</h1>
    }
  }
  if (process.env.NODE_ENV === 'production') {
    captureRemixErrorBoundaryError(error)
  }
  return <Document>{renderError()}/</Document>
}
